
import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import Table from '@/components/Table/Table.vue'
import FForm from '@/components/Form/FForm.vue'
import FCheckbox from '@/components/Form/FCheckbox.vue'
import Modal from '@/components/Modal/Modal.vue'
import HelpIcon from '@/components/HelpIcon/HelpIcon.vue'
import FButton from '@/components/Button/FButton.vue'
import { TableColumn } from '@/models/TableColumn';
import { ApiService } from '@/services/ApiService'
import { ProfileService } from '@/services/ProfileService'
import { PaymentSettingData } from '@/models/PaymentSettingData'
import { PaymentSettingsDetails } from '@/models/PaymentSettingsDetails'
import { PaymentSettingLocationData } from '@/models/PaymentSettingLocationData'
import { BusyService } from '@/services/BusyService'
import { ToastService } from '@/services/ToastService'
import ConfirmService from '@/services/ConfirmService'
import { i18n } from '@/main'
import { cloneDeep } from "lodash";
import { DynamicTranslationService } from '@/services/DynamicTranslationService'

@Component({
  components: {
    CardWithTitle,
    Table,
    FForm,
    FCheckbox,
    FButton,
    Modal,
    HelpIcon
  },
  metaInfo: () => ({
    title: DynamicTranslationService.paymentSettingsTitle
  }),
  beforeRouteEnter: (to, from, next) => {
    if (ProfileService.enableDirectDeposit) {
      next();
    } else {
      next('/payment')
    }
  },
  async beforeRouteLeave(this: EditPaymentMethod, to, from, next) {
    if (this.isSubmitting) {
      next();
      return;
    }
    if (this.hasUnsavedChanges) {
      const success = await ConfirmService.showConfirm({
          title: this.$t('R.LIT_ContinueWithoutSaving').toString(),
          message: this.$t('R.MES_UnsavedChangesMsg').toString(),
          okLabel: this.$t('R.LIT_ContinueWithoutSaving').toString(),
          cancelLabel: this.$t('G.LIT_Cancel').toString()
        });

        if (success){
          next();
        } else {
          next(false);
        }
    } 
      else {
        next();
      }
  }
})
export default class EditPaymentMethod extends Vue {
  locationData: PaymentSettingLocationData = {
    paymentSettingId: '',
    payoutLocations: []
  };

  details: { details: any }[] = [];
  paymentSettings: PaymentSettingsDetails | null = null
  partyCountry = ProfileService.profile?.org.countryCode.toUpperCase() || '';

  originalData: PaymentSettingLocationData = {
    paymentSettingId: '',
    payoutLocations: []
  };

  isSubmitting = false;


  get tableColumns(): TableColumn[] {
    return [
      { key: 'details', label: i18n.t('R.LIT_Details').toString() },
    ];
  }

  async mounted() {
    this.isSubmitting = false;
    if (!ProfileService.profile) {
      return;
    }
    BusyService.showBusy();
    this.locationData.paymentSettingId = this.$route.params.id;
    const payments: { paymentSettings: any[], lockedCountries: string[] } = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
    const paymentData = payments.paymentSettings.find((p: PaymentSettingData) => p.paymentSettingId === this.locationData.paymentSettingId);
    this.paymentSettings = { paymentSettings: payments.paymentSettings, lockedCountries: payments?.lockedCountries || [] };
    this.locationData.payoutLocations = paymentData?.payoutLocations || [];
    this.originalData = cloneDeep(this.locationData);
    if (paymentData) {
      this.details = [{ details: paymentData.paymentMethodDetails }];
    }
    BusyService.hideBusy();
  }


  get payoutLocations() {
    return [
      {
        value: 'NA',
        label: 'North America',
        disabled: this.originalData?.payoutLocations.includes('NA') || this.paymentSettings?.lockedCountries.includes('NA'),
        forceDisable: false
      },
      {
        value: 'UKIE',
        label: 'UK & Ireland',
        disabled: this.originalData?.payoutLocations.includes('UKIE') || this.paymentSettings?.lockedCountries.includes('UKIE'),
        forceDisable: false
      },
      {
        value: 'AP',
        label: 'Asia Pacific',
        disabled: this.originalData?.payoutLocations.includes('AP') || this.paymentSettings?.lockedCountries.includes('AP'),
        forceDisable: false
      },
      {
        value: 'AF',
        label: 'Africa',
        disabled: this.originalData?.payoutLocations.includes('AF') || this.paymentSettings?.lockedCountries.includes('AF'),
        forceDisable: false
      },
      {
        value: 'EU',
        label: 'Mainland Europe',
        disabled: this.originalData?.payoutLocations.includes('EU') || this.paymentSettings?.lockedCountries.includes('EU'),
        forceDisable: false
      },
      {
        value: 'KR',
        label: 'Korea',
        disabled: this.originalData?.payoutLocations.includes('KR') || this.paymentSettings?.lockedCountries.includes('KR'),
        forceDisable: this.partyCountry !== 'KR' || false
      },
    ]
  }

  isDefaultPayment = false;

  get paymentSetting() {
    return this.paymentSettings?.paymentSettings.find(p => p.paymentSettingId === this.locationData.paymentSettingId);
  }

  get hasUnsavedChanges() {
    return JSON.stringify(this.locationData) !== JSON.stringify(this.originalData);
  }

  get CannotSetPayoutLocationMessage() {
    return this.$t('R.MES_CannotSetRegionPayoutLocation');
  }

  isCountryChecked(locationValue: string) {
    return this.paymentSetting?.payoutLocations.includes(locationValue);
  }

  updatePayoutLocations(locationValue: string, isChecked: boolean) {
    if (isChecked) {
      if (locationValue === 'ALL') {
        this.isDefaultPayment = true;
        this.locationData.payoutLocations = this.payoutLocations.filter(l => !this.paymentSettings?.lockedCountries.includes(l.value) && !l.forceDisable).map(l => l.value);
        return;
      }
      this.locationData.payoutLocations.push(locationValue);
    } else {
      if (locationValue === 'ALL') {
        this.isDefaultPayment = false;
      }
      const index = this.locationData.payoutLocations.indexOf(locationValue);
      if (index !== -1) {
        this.locationData.payoutLocations.splice(index, 1);
      }
    }
  }

  checkExistingPaymentLocations(): boolean {
    if (this.paymentSettings && this.paymentSettings.paymentSettings.length > 1) {
      for (const payment of this.paymentSettings.paymentSettings) {
        if (payment.paymentSettingId !== this.locationData.paymentSettingId) {
          const locationIsBeingUsed = payment.payoutLocations.some(location => this.locationData.payoutLocations.includes(location));
          if (locationIsBeingUsed) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async onSubmit() {
    if ((this.$refs.form as FForm).hasFormError) {
      return;
    }
    this.isSubmitting = true;
    
    const checkExistingPaymentLocations = this.checkExistingPaymentLocations();
    if(checkExistingPaymentLocations){
        const success = await ConfirmService.showConfirm({
          title: this.$t('G.LIT_Save').toString(),
          message: this.$t('R.MES_PaymentEditConfirmationMsg').toString(),
          okLabel: this.$t('G.LIT_Okay').toString(),
          cancelLabel: this.$t('G.LIT_Cancel').toString()
        });

        if (!success){
          return
        }
    }
    BusyService.showBusy();
    await ApiService.post('/api/SetPaymentSettingLocations', { data: JSON.stringify(this.locationData) })
    if (this.paymentSettings && this.paymentSettings.paymentSettings.length > 0) {
      this.paymentSettings.paymentSettings.map(p => p).forEach(async (payment) => {
        if (payment.paymentSettingId !== this.locationData.paymentSettingId) {
          const filterLocationData = payment.payoutLocations.filter(l => !this.locationData.payoutLocations.includes(l));
          const payoutLocationData = {
            paymentSettingId: payment.paymentSettingId,
            payoutLocations: filterLocationData
          }
          await ApiService.post('/api/SetPaymentSettingLocations', { data: JSON.stringify(payoutLocationData) });
        }
      });
    }

    // to ensure the payoutlocations are updated in the payment settings page
    await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile?.org.id });

    BusyService.hideBusy();
    ToastService.successToast('Payment method updated successfully')
    this.$router.push('/payment')
  }

}
